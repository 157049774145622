import moment from "moment";
  
export function formatDate(timestamp, format = "DD/MM/YYYY hh:mm a") {
    if (timestamp === 0) {
      return "N/A";
    }
    return moment(timestamp).format(format);
  }
export function formatDateNew(timestamp, format = "DD/MM/YYYY") {
    if (!timestamp) return "N/A";
    return moment(timestamp.seconds * 1000).format(format);
  }
export const formatDateForHome=(timestamp,format = "YYYY-MM-DDTHH:MM:SS")=>{
  if (!timestamp) return "N/A";
  let date= moment(timestamp.seconds * 1000).format(format);
  var t = new Date(date);
 
  var curr_date = t.getDate();
  var curr_month = t.getMonth();
  var curr_year = t.getFullYear();
 return daysDiff(curr_date + "-" + curr_month + "-" + curr_year);

};
const daysDiff=(date)=> {
  var today = new Date();
  var curr_date = today.getDate();
  var curr_month = today.getMonth(); //Months are zero based
  var curr_year = today.getFullYear();
  var tdate = curr_date + "-" + curr_month + "-" + curr_year;

  return restaFechas(date, tdate);
}
// Función para calcular los días transcurridos entre dos fechas
const restaFechas=(f1, f2)=> {
  var aFecha1 = f1.split("-");
  var aFecha2 = f2.split("-");
  var fFecha1 = Date.UTC(aFecha1[2], aFecha1[1] - 1, aFecha1[0]);
  var fFecha2 = Date.UTC(aFecha2[2], aFecha2[1] - 1, aFecha2[0]);
  var dif = fFecha2 - fFecha1;
  var dias = Math.floor(dif / (1000 * 60 * 60 * 24));
  return dias;
}